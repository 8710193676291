import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from "../../commoncomponents/FormInput";
import { getUsersAction } from "../../redux/actions/users/getUsersAction";
import { updateUserAction } from "../../redux/actions/users/updateUserAction";
import { clearaddUserState } from "../../redux/reducers/addUserReducer";
import { clearUpdateUserState } from "../../redux/reducers/updateUserReducer";
import { userForm } from "../../utils/addUserFormFields";

const EditUser = ({ setEditModal, editedData }) => {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const userReducer = useSelector((state) => state.updateUserReducer);
  const { gst } = editedData;

  const [data, setData] = useState({
    ...editedData,
    gst: String(gst),
  });

  const [visible, setVisible] = useState(true);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = () => {
    dispatch(updateUserAction(data));
  };

  const userFields = userForm(data, error, handleChange);

  useEffect(() => {
    if (userReducer?.data?.status) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: userReducer?.data?.message,
        life: 3000,
      });
      setTimeout(() => {
        dispatch(
          getUsersAction({ searchValue: "", signal: null })
        );
        dispatch(clearUpdateUserState());
        setEditModal(false);
      }, 1500);
    } else if (userReducer?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: userReducer?.data?.message,
        life: 3000,
      });
      dispatch(clearaddUserState());
    } else if (userReducer?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: userReducer?.error,
        life: 3000,
      });
      dispatch(clearaddUserState());
    }
  }, [userReducer, dispatch]);

  return (
    <>
      <Dialog
        header="Edit User"
        visible={visible}
        style={{ width: "80vw" }}
        draggable={false}
        onHide={() => {
          setVisible(false);
          setEditModal(false);
        }}
      >
        <div className="rounded">
          <div className="row p-2 max-h-[60vh] overflow-auto">
            {userFields.map((input, index) => {
              if (
                input?.name === "password" ||
                input?.name === "confirm_password"
              ) {
                return <></>;
              }
              return (
                <div
                  className={
                    input?.name === "address"
                      ? "col-12"
                      : "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"
                  }
                  key={`${input.name}_${index}`}
                >
                  <FormInput
                    labelName={input.label}
                    required={input.required}
                    type={input.type}
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    placeholder={input.placeholder}
                    error={input.error}
                  />
                </div>
              );
            })}
          </div>
          <div className="row p-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end ">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
              <button
                className="btn btn-light ml-1"
                onClick={() => {
                  setEditModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default EditUser;
