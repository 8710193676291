export const userForm = (data, error, handleChange) => [
  {
    label: "First Name",
    required: true,
    name: "first_name",
    value: data?.first_name,
    onChange: handleChange,
    placeholder: "First Name",
    error: error?.first_name,
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    placeholder: "Last Name",
    required: true,
    value: data?.last_name,
    onChange: handleChange,
    error: error?.last_name,
  },
  {
    label: "Company Name",
    name: "company",
    type: "text",
    placeholder: "Company Name",
    required: true,
    value: data?.company,
    onChange: handleChange,
    error: error?.company,
  },
  {
    label: "Email",
    name: "email",
    type: "text",
    placeholder: "Email",
    required: true,
    value: data?.email,
    onChange: handleChange,
    error: error?.email,
  },
  {
    label: "Mobile",
    name: "mobile",
    type: "number",
    placeholder: "Mobile",
    required: true,
    value: data?.mobile,
    onChange: handleChange,
    error: error?.mobile,
  },
  {
    label: "GST",
    name: "gst",
    type: "text",
    placeholder: "GST",
    required: true,
    value: data?.gst,
    onChange: handleChange,
    error: error?.gst,
  },
  {
    label: "Address",
    name: "address",
    type: "textarea",
    placeholder: "Address",
    required: true,
    value: data?.address,
    onChange: handleChange,
    error: error?.address,
  },
  {
    label: "State",
    name: "state",
    type: "text",
    placeholder: "State",
    required: true,
    value: data?.state,
    onChange: handleChange,
    error: error?.state,
  },
  {
    label: "City",
    name: "city",
    type: "text",
    placeholder: "City",
    required: true,
    value: data?.city,
    onChange: handleChange,
    error: error?.city,
  },
  {
    label: "Pincode",
    name: "pin_code",
    type: "number",
    placeholder: "Pincode",
    required: true,
    value: data?.pin_code,
    onChange: handleChange,
    error: error?.pin_code,
  },
  {
    label: "Location (longitude and latitude)",
    name: "lat_long",
    type: "text",
    placeholder: "23.022505,72.571365",
    required: true,
    value: data?.lat_long,
    onChange: handleChange,
    error: error?.lat_long,
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Password",
    required: true,
    value: data?.password,
    onChange: handleChange,
    error: error?.password,
  },
  {
    label: "Confirm Password",
    name: "confirm_password",
    type: "password",
    placeholder: "Confirm Password",
    required: true,
    value: data?.confirm_password,
    onChange: handleChange,
    error: error?.confirm_password,
  },
  {
    label: "Email ID for notification",
    name: "notification_email",
    type: "text",
    placeholder: "example@gmail.com,example@gmail.com,...",
    required: true,
    value: data?.notification_email,
    onChange: handleChange,
    error: error?.notification_email,
  },
];
