import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import allControlPanelShow from "../../redux/actions/controlPanel/allControlPanelShow";
import { clearAllControlPanelShow } from "../../redux/reducers/controlPanel/allControlPanelReducer";
import {
  AllControlPanelTabs,
  AllCTPFiltersOptions,
} from "../../utils/data";
import AllCTPTabs from "./AllCTPTabs";

const ControlPanel = () => {
  const { tabName } = useParams();
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [selectedDays, setSelectedDays] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [allData, setAllData] = useState(null);
  const allCTPData = useSelector((state) => state?.allControlPanelData);
  const { role } = JSON.parse(localStorage.getItem("userData")) ?? {};

  useEffect(() => {
    let selectedFilter;
    if (tabName === "devices") {
      selectedFilter = AllControlPanelTabs?.filter((i) =>
        [
          "Offline Device",
          "Temporary Offline Device",
          "Long Term Offline Device",
        ]?.includes(i?.value)
      );
    }
    if (tabName === "modules") {
      selectedFilter = AllControlPanelTabs?.filter((i) =>
        [
          "Offline Module",
          "Temporary Offline Module",
          "Long Term Offline Module",
        ]?.includes(i?.value)
      );
    }
    if (tabName === "alarms") {
      selectedFilter = AllControlPanelTabs?.filter((i) =>
        ["Acknowledged Alarm", "Unacknowledged Alarm"]?.includes(i?.value)
      );
    }
    if (tabName === "trips") {
      selectedFilter = AllControlPanelTabs?.filter((i) =>
        ["Acknowledged Trip", "Unacknowledged Trip"]?.includes(i?.value)
      );
    }
    if (tabName === "all") {
      selectedFilter = AllControlPanelTabs?.filter((i) =>
        ["All"]?.includes(i?.value)
      );
    }
    setSelectedDevice(selectedFilter?.map((f) => f?.value));
  }, [tabName]);

  useEffect(() => {
    if (selectedDevice.length) {
      dispatch(
        allControlPanelShow({
          status: selectedDevice,
          days: selectedDays,
        })
      );
    }
  }, [dispatch, selectedDevice, selectedDays]);

  useEffect(() => {
    if (!allCTPData?.loading && allCTPData?.data?.length) {
      const data = allCTPData?.data;
      const sortedData = [...data]?.sort((a, b) => {
        const dateA = moment(a.EventStartedOn, "DD-MM-YYYY HH:mm");
        const dateB = moment(b.EventStartedOn, "DD-MM-YYYY HH:mm");
        return dateB - dateA;
      });

      setAllData(sortedData);
      setIsLoading(false);
    } else if (!allCTPData?.data?.length) {
      setAllData(null);
    }
  }, [allCTPData]);

  useEffect(() => {
    if (allCTPData?.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [allCTPData]);

  return (
    <>
      <div className="main-panel">
        <div className="container-wrapper manageUser">
          <div className="container-fluid py-3">
            <div className="height-100">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-2">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                        <div className="card flex justify-center">
                          <Dropdown
                            value={selectedDays}
                            onChange={(e) => {
                              setAllData(null);
                              dispatch(clearAllControlPanelShow());
                              setSelectedDays(e.target.value);
                            }}
                            options={role === 0 ? AllCTPFiltersOptions : AllCTPFiltersOptions?.slice(0,5) }
                            optionLabel="name"
                            placeholder="Action type"
                            className="w-full md:w-14rem !border border-[#ced4da] hover:border-[#fe4c4c] rounded z-100 h-[40px] event-filter"
                            filter
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-0 col-0"></div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                        <div className="card flex justify-center">
                          <MultiSelect
                            title={selectedDevice?.map(
                              (element) => element?.name
                            )}
                            value={selectedDevice}
                            filter
                            onChange={(e) => {
                              if (e.value[e.value?.length - 1] === "All") {
                                setSelectedDevice(["All"]);
                              } else {
                                setSelectedDevice(
                                  e.value?.filter((k) => k !== "All")
                                );
                              }
                            }}
                            options={AllControlPanelTabs}
                            optionLabel="name"
                            display="chip"
                            placeholder="Select Events"
                            maxSelectedLabels={3}
                            className="w-full md:w-14rem h-[40px] !border border-[#ced4da] hover:border-[#fe4c4c] rounded z-100 ctp-multiselect"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt-3">
                      {/* <Outlet /> */}
                      <AllCTPTabs allCTPData={allData} isLoading={isLoading} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ControlPanel;
