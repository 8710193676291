import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductValidation } from "../../validations/addNodeValidation";
import { clearaddProductState } from "../../redux/reducers/addProductReducer";
import { editProductAction } from "../../redux/actions/product/editProductAction";
import { Toast } from "primereact/toast";
import { getProductAction } from "../../redux/actions/product/getProductAction";
import { FormInput } from "../../commoncomponents/FormInput";
import { generateFormInputs } from "../../utils/addNodeFormInput";

const EditProduct = ({ setOpen, editData }) => {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [data, setData] = useState({
    ...editData,
    site_name: editData?.site_name || "",
    site_area_location: editData?.site_area_location || "",
    remarks: editData?.description || "",
  });
  const [visible, setVisible] = useState(true);
  const [error, setError] = useState(null);
  const addNode = useSelector((state) => state.addNode);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = () => {
    const editedData = {
      ...data,
      name: data.name ? data.name : "",
      site_name: data.site_name ? data.site_name : "",
      site_area_location: data.site_area_location
        ? data.site_area_location
        : "",
      remarks: data.remarks ? data.remarks : "",
      latitude: data.latitude ? data.latitude : "",
      longitude: data.longitude ? data.longitude : "",
      description: data.remarks ? data.remarks : "",
    };
    const errors = addProductValidation(editedData);
    if (Object.keys(errors).length === 0) {
      dispatch(editProductAction(editedData));
    } else {
      setError(errors);
    }
  };

  const formInputs = generateFormInputs(data, error, handleChange);

  useEffect(() => {
    if (addNode?.editData?.status === true) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: addNode?.editData.message,
        life: 3000,
      });
      dispatch(clearaddProductState());
      setTimeout(() => {
        setOpen(false);
        dispatch(getProductAction({ value: "" }));
      }, 1500);
    } else if (addNode?.editData?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: addNode?.editData.message,
        life: 3000,
      });
      dispatch(clearaddProductState());
    } else if (addNode?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: addNode.error,
        life: 3000,
      });
      dispatch(clearaddProductState());
    }
  }, [addNode, setOpen, dispatch]);

  return (
    <>
      <div className="card flex justify-content-center">
        <Dialog
          header="Edit Node"
          visible={visible}
          draggable={false}
          style={{ width: "80vw" }}
          onHide={() => {
            setVisible(false);
            setOpen(false);
          }}
        >
          <div className="card-body rounded">
            <div className="row p-3 max-h-[50vh] overflow-auto">
              {formInputs.map((input, index) => (
                <div
                  className={
                    input?.name === "remarks"
                      ? "col-12"
                      : "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"
                  }
                  key={`${input.name}_${index}`}
                >
                  <FormInput
                    type={input.type}
                    labelName={input.labelName}
                    required={input.required}
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    placeholder={input.placeholder}
                    error={input.error}
                  />
                </div>
              ))}
            </div>
            <div className="row p-3">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end ">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={addNode?.loading}
                >
                  Submit
                </button>
                <button
                  className="btn btn-light ml-1"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default EditProduct;
