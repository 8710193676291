import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("auth-token")
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "auth-token") {
        if (event.newValue !== null) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const cleanup = () => {
      // const itemStr = localStorage.getItem("sessionTime");
      // if (itemStr) {
      //   const item = JSON.parse(itemStr);
      //   const now = new Date();
      //   const sessionStartTime = new Date(item);
      //   sessionStartTime.setHours(sessionStartTime.getHours() + 1)
      //   if (sessionStartTime.getTime() === now.getTime()) {
      //   }
      // }
      localStorage.clear();
      window.location.href = "/";
    };
    const sessionTimeOut = setTimeout(cleanup, 60 * 60 * 1000);
    return () => clearTimeout(sessionTimeOut)
  }, []);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
