import {
  faChevronDown,
  faChevronUp,
  faDashboard,
  faGears,
  faList,
  faSignOut,
  faSolarPanel,
  faTachographDigital,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clearLoginState } from "../redux/reducers/loginReducer";
import { linksData } from "../utils/data";

const Sidebar = ({ showSubTab, handleSubTab, setShowSideBar }) => {
  const { role } = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({ type: "logout" });
    dispatch(clearLoginState());
    localStorage.removeItem("auth-token");
    localStorage.removeItem("userData");
    localStorage.removeItem("showPause");
    // resetAudio();
    localStorage.clear();
    // navigate("/")
    window.location.href = "/";
  };

  return (
    <nav
      className={`sidebar sidebar-offcanvas ${role === 1 && "md:hidden"}`}
      id="sidebar"
    >
      <ul className="sidebar-menu">
        <li>
          <Link
            className="card-link p-0 hover:text-primary"
            to="/home"
            onClick={() => setShowSideBar(false)}
          >
            <div className="border-none bg-white hover:!bg-[rgba(0,0,0,0.05)] rounded-lg !text-[16px] p-1">
              <FontAwesomeIcon icon={faDashboard} />
              <span className="ml-2">Dashboard</span>
            </div>
          </Link>
        </li>
        {/* {role === 0 ? ( */}
        <>
          <li>
            {/* <div className="card-header border-none pb-1 pt-0 bg-white"> */}
            <Link
              to={"/control-panel/all"}
              className="card-link p-0"
              onClick={() => setShowSideBar(false)}
            >
              <div className="border-none bg-white hover:!bg-[rgba(0,0,0,0.05)] rounded-lg !text-[16px] p-1">
                <FontAwesomeIcon icon={faSolarPanel} />
                <span className="ml-1">Control Panel</span>
              </div>
            </Link>
          </li>
          <li>
            <Link
              to={"/device-list"}
              className="card-link p-0"
              onClick={() => setShowSideBar(false)}
            >
              <div className="border-none bg-white hover:!bg-[rgba(0,0,0,0.05)] rounded-lg !text-[16px] p-1">
                <FontAwesomeIcon icon={faList} />
                <span className="ml-2 ">Device Lists</span>
              </div>
            </Link>
          </li>
          <li>
            <Link
              to="/device-status"
              className="card-link p-0"
              onClick={() => setShowSideBar(false)}
            >
              <div className="border-none bg-white hover:!bg-[rgba(0,0,0,0.05)] rounded-lg !text-[16px] p-1">
                <FontAwesomeIcon icon={faTachographDigital} />
                <span className="ml-1">Devices Status</span>
              </div>
            </Link>
          </li>
          {role === 0 && (
            <li>
              <div className="w-full">
                <p
                  className="text-[#FE4C4C] card-link p-0 hover:text-[#414141]"
                  onClick={() => handleSubTab("tab1")}
                >
                  <div className="border-none bg-white hover:!bg-[rgba(0,0,0,0.05)] rounded-lg !text-[16px] p-1 cursor-pointer">
                    <FontAwesomeIcon icon={faGears} />
                    <span className="ml-1">System Settings</span>
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon={showSubTab?.tab1 ? faChevronUp : faChevronDown}
                      />
                    </div>
                  </div>
                </p>
                {showSubTab?.tab1 && (
                  <div className="card-body pt-1 ms-3 text-md">
                    {linksData?.map((link, index) => (
                      <Link
                        to={link.to}
                        className="hover:text-[#414141]"
                        onClick={() => setShowSideBar(false)}
                      >
                        <div
                          key={index}
                          className="col-lg-12 col-md-12 col-sm-12 col-12 mb-1 hover:!bg-[rgba(0,0,0,0.05)] rounded-lg !text-[16px] p-1"
                        >
                          {link.label}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </li>
          )}
          <li>
            <Link
              to="#!"
              className="card-link p-0 md:hidden block"
              onClick={handleLogout}
            >
              <div className="border-none bg-white hover:!bg-[rgba(0,0,0,0.05)] rounded-lg !text-[16px] p-1">
                <FontAwesomeIcon
                  className="ml-[2px] mr-[4px]"
                  icon={faSignOut}
                />
                <span className="ml-1">Logout</span>
              </div>
            </Link>
          </li>
        </>
      </ul>
    </nav>
  );
};

export default Sidebar;
