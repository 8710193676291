import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const UserDevice = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const { assign_product, id } =
    JSON.parse(localStorage.getItem("userData")) || {};

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-between">
        <h5 className="text-xl">Device List</h5>
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="form-control form-control-sm ml-1"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const actionColumnBody = (row) => {
    return <Link to={`/details/${row.id}/${id}/1`}>View</Link>;
  };

  return (
    <div className="w-full">
      <div className="m-3 p-2 bg-white">
        <div className="card">
          <DataTable
            header={header}
            filters={filters}
            value={assign_product}
            removableSort
            paginator={!!assign_product?.length}
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            totalRecords={assign_product?.length}
            emptyMessage={"No devices found."}
            showGridlines
          >
            <Column
              className="border-1 text-[14px] p-1 text-center"
              header="Sr No"
              body={(_, { rowIndex }) => rowIndex + 1}
            ></Column>
            <Column
              className="border-1 text-[14px] p-1 text-center"
              field="name"
              header="Node Name"
              sortable
            ></Column>
            <Column
              className="border-1 text-[14px] p-1 text-center"
              field="site_name"
              header="Site name"
              sortable
            ></Column>
            <Column
              className="border-1 text-[14px] p-1 text-center"
              field="description"
              header="Description"
              sortable
            ></Column>
            <Column
              className="border-1 text-[14px] p-1 text-center"
              field="location"
              header="Location"
              sortable
            ></Column>
            <Column
              className="border-1 text-[14px] p-1 text-center"
              header="Action"
              body={actionColumnBody}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default UserDevice;
