import { combineReducers, configureStore } from "@reduxjs/toolkit";
import addAssignedModuleReducer from "./reducers/addAssignedModuleReducer";
import addCityReducer from "./reducers/addCityReducer";
import addProductReducer from "./reducers/addProductReducer";
import addUserReducer from "./reducers/addUserReducer";
import accessModuleReducer from "./reducers/admin/accessModuleReducer";
import getChartDataReducer from "./reducers/admin/getChartDataReducer";
import getDataByDateReducer from "./reducers/admin/getDataByDateReducer";
import getDataInXslxReducer from "./reducers/admin/getDataInXslxReducer";
import getModuleColumnsReducer from "./reducers/admin/getModuleColumnsReducer";
import getTimeSeriesDataReducer from "./reducers/admin/getTimeSeriesDataReducer";
import getUsersWithNodeReducer from "./reducers/admin/getUsersWithNodeReducer";
import alarmTripCountReducer from "./reducers/alarmTripCount/alarmTripCountReducer";
import onlineOfflineCountReducer from "./reducers/alarmTripCount/onlineOfflineCountReducer";
import onlineOfflineNodeReducer from "./reducers/alarmTripCount/onlineOfflineNodeReducer";
import addCategoryReducer from "./reducers/categories/addCategoryReducer";
import getCategoriesReducer from "./reducers/categories/getCategoriesReducer";
import alarmListReducer from "./reducers/controlPanel/alarmListReducer";
import allControlPanelReducer from "./reducers/controlPanel/allControlPanelReducer";
import nodeListReducer from "./reducers/controlPanel/nodeListReducer";
import onlineOfflineDeviceReducer from "./reducers/controlPanel/onlineOfflineDeviceReducer";
import tripListReducer from "./reducers/controlPanel/tripListReducer";
import accessEventsReducer from "./reducers/events/accessEventsReducer";
import addAssignedEventReducer from "./reducers/events/addAssignedEventReducer";
import getEventListReducer from "./reducers/events/getEventListReducer";
import getEventsTableReducer from "./reducers/events/getEventsTableReducer";
import updateEventStatusReducer from "./reducers/events/updateEventStatusReducer";
import forgotPasswordReducer from "./reducers/forgotPassword/forgotPasswordReducer";
import addAssignedLicenseReducer from "./reducers/license/addAssignedLicenseReducer";
import getAssignedLicenseReducer from "./reducers/license/getAssignedLicenseReducer";
import loginReducer from "./reducers/loginReducer";
import offlineDeviceNameReducer from "./reducers/offlineDeviceNameReducer";
import getProductReducer from "./reducers/reducer/getProductReducer";
import updateUserReducer from "./reducers/updateUserReducer";
import getUsersReducer from "./reducers/users/getUsersReducer";
import updateUserStatusReducer from "./reducers/users/updateUserStatusReducer";

// const store = configureStore({
//   reducer: {
//     login: loginReducer,
//     addUser: addUserReducer,
//     addCity: addCityReducer,
//     addNode: addProductReducer,
//     addCategory: addCategoryReducer,
//     productsData: getProductReducer,
//     categoriesData: getCategoriesReducer,
//     getUsers: getUsersReducer,
//     addAssignedLicense: addAssignedLicenseReducer,
//     getAssignedLicense: getAssignedLicenseReducer,
//     addAssignedModuleReducer: addAssignedModuleReducer,
//     accessModule: accessModuleReducer,
//     getDataByDateReducer: getDataByDateReducer,
//     getUsersWithNodeReducer: getUsersWithNodeReducer,
//     getChartDataReducer: getChartDataReducer,
//     updateUserReducer: updateUserReducer,
//     eventsData: getEventListReducer,
//     addAssignedEventReducer: addAssignedEventReducer,
//     accessEvents: accessEventsReducer,
//     getEventsTable: getEventsTableReducer,
//     updateEventStatusData: updateEventStatusReducer,
//     updateUserStatusData: updateUserStatusReducer,
//     getDataInXslxData: getDataInXslxReducer,
//     getModuleColumnsData: getModuleColumnsReducer,
//     getTimeSeriesDataReducer: getTimeSeriesDataReducer,
//     alarmTripCountReducer: alarmTripCountReducer,
//     onlineOfflineCountReducer: onlineOfflineCountReducer,
//     // totalAlarmCountReducer: totalAlarmCountReducer,
//     onlineOfflineNodeReducer: onlineOfflineNodeReducer,
//     offlineDeviceNameReducer: offlineDeviceNameReducer,
//     forgotPasswordReducer: forgotPasswordReducer,
//     onlineOfflineDeviceReducer: onlineOfflineDeviceReducer,
//     nodeListData: nodeListReducer,
//     alarmListData: alarmListReducer,
//     tripListData: tripListReducer,
//     allControlPanelData: allControlPanelReducer,
//   },
// });
const appReducer = combineReducers({
  login: loginReducer,
  addUser: addUserReducer,
  addCity: addCityReducer,
  addNode: addProductReducer,
  addCategory: addCategoryReducer,
  productsData: getProductReducer,
  categoriesData: getCategoriesReducer,
  getUsers: getUsersReducer,
  addAssignedLicense: addAssignedLicenseReducer,
  getAssignedLicense: getAssignedLicenseReducer,
  addAssignedModuleReducer: addAssignedModuleReducer,
  accessModule: accessModuleReducer,
  getDataByDateReducer: getDataByDateReducer,
  getUsersWithNodeReducer: getUsersWithNodeReducer,
  getChartDataReducer: getChartDataReducer,
  updateUserReducer: updateUserReducer,
  eventsData: getEventListReducer,
  addAssignedEventReducer: addAssignedEventReducer,
  accessEvents: accessEventsReducer,
  getEventsTable: getEventsTableReducer,
  updateEventStatusData: updateEventStatusReducer,
  updateUserStatusData: updateUserStatusReducer,
  getDataInXslxData: getDataInXslxReducer,
  getModuleColumnsData: getModuleColumnsReducer,
  getTimeSeriesDataReducer: getTimeSeriesDataReducer,
  alarmTripCountReducer: alarmTripCountReducer,
  onlineOfflineCountReducer: onlineOfflineCountReducer,
  onlineOfflineNodeReducer: onlineOfflineNodeReducer,
  offlineDeviceNameReducer: offlineDeviceNameReducer,
  forgotPasswordReducer: forgotPasswordReducer,
  onlineOfflineDeviceReducer: onlineOfflineDeviceReducer,
  nodeListData: nodeListReducer,
  alarmListData: alarmListReducer,
  tripListData: tripListReducer,
  allControlPanelData: allControlPanelReducer,
});
 
// Step 2: Enhance the Root Reducer to Reset State on Logout
const rootReducer = (state, action) => {
  if (action.type === 'logout') {
    state = undefined; // Reset all state to initial values
  }
  return appReducer(state, action);
};
 
// Step 3: Add Middleware to Listen for `logout` Action
const clearStoreMiddleware = (store) => (next) => (action) => {
  if (action.type === 'logout') {
    // console.log('Clearing store on logout...');
  }
  return next(action);
};
 
// Step 4: Configure the Store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(clearStoreMiddleware),
});
 
export default store;
