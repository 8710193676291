import { createSlice } from "@reduxjs/toolkit";
import { alarmList } from "../../actions/controlPanel/alarmList";
import { converDate } from "../../../utils/converData";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const alarmListReducer = createSlice({
  name: "alarmList",
  initialState,
  reducers: {
    // clearnodeList: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(alarmList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(alarmList.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.data = action.payload.map((item) => ({
            ...item,
            EventStartedOn: converDate(item.EventStartedOn),
            EventEndedOn: converDate(item.EventEndedOn),
          }));
        }
      })
      .addCase(alarmList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { clearalarmList } = getCategoriessSlice.actions;
export default alarmListReducer.reducer;
