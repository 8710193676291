import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const AudioContext = createContext();

// Create a provider component
export const AudioProvider = ({ children }) => {
  const audioRef = useRef(null);
  const localStorageValue =
    localStorage.getItem("showPause") === "1" ? true : false;
  const [isStopped, setIsStopped] = useState(
    localStorage.getItem("showPause") !== null ? localStorageValue : null
  );
  const [isHideIcon, setIsHideIcon] = useState(false);
  const [iconLoading, setIconLoading] = useState(false);

  const playAudio = () => {
    if (audioRef.current && !isStopped) {
      audioRef.current.play();
    }
  };

  const hideIcon = () => {
    setIsHideIcon(true);
  };

  const handleIconLoading = () => {
    setIconLoading(true);
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsStopped(true);
    }
  };

  // useEffect(() => {
  //   console.log("local storage value", localStorage.getItem("showPause"));
  // }, [localStorage]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "showPause") {
        if (event.newValue !== null) {
          setIsStopped(event.newValue === "1" ? true : false);
        } else {
          setIsStopped(false);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <AudioContext.Provider
      value={{ playAudio, hideIcon, stopAudio, audioRef, isStopped, isHideIcon, handleIconLoading, iconLoading }}
    >
      <audio preload="auto" ref={audioRef} src="buzzer.mp3" loop />
      {children}
    </AudioContext.Provider>
  );
};

// Custom hook to use the audio context
export const useAudio = () => {
  return useContext(AudioContext);
};
